<template>
    <div>
        <h1>Items Shipped</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./curbs_shipped_opt";
    import Results from "./utils/Results";
    export default {
        name: 'curbs_shipped',
        components: {Results, Options},
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_from]'},
                    endDate: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][cdi_location_id]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Items Shipped']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>